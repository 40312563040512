import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import siteSnapshot from '../../images/site-snapshot.png';
import icon from '../../images/icon.png';

const Seo = ({ lang, seoData }) => {
  const data = seoData;
  return (
    <Helmet htmlAttributes={{ lang }}>
      <title itemProp="name" lang="en">
        {data.title}
      </title>
      <link rel="shortcut icon" href={icon} />
      <meta name="description" content={data.description} />
      <meta property="og:title" content={data.title} />
      <meta property="og:description" content={data.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={data.url} />
      <meta property="og:site_name" content={data.title} />
      <meta property="og:image" content="https://mikeharvey.me/static/site-snapshot-0e19463e8130c16bc5c01d9717cf8442.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="600" />
      <meta property="og:image:type" content="image/png" />
      <meta itemProp="name" content={data.title} />
      <meta itemProp="description" content={data.description} />
      <meta itemProp="image" content="https://mikeharvey.me/static/site-snapshot-0e19463e8130c16bc5c01d9717cf8442.png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={data.url} />
      <meta name="twitter:site" content={data.author} />
      <meta name="twitter:creator" content={data.author} />
      <meta name="twitter:title" content={data.title} />
      <meta name="twitter:description" content={data.description} />
      <meta name="twitter:image" content="https://mikeharvey.me/static/site-snapshot-0e19463e8130c16bc5c01d9717cf8442.png" />
      <meta name="twitter:image:alt" content={data.title} />
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "mikeharvey.me",
            "item": "https://www.mikeharvey.me"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Home",
            "item": "https://www.mikeharvey.me"
          }]
        }
      `}
      </script>
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: 'en',
};

Seo.propTypes = {
  lang: PropTypes.string,
};

export default Seo;
